import React from 'react'
import { Navigate, RouteObject } from 'react-router'
import {PrivateWrapper} from "@/routes/PrivateWrapper";

export const ROUTES: RouteObject[] = [
  {
    path: 'login',
    element: <Navigate to={`/auth/login${window.location.search}`} replace />,
  },
  {
    path: 'registration',
    element: <Navigate to="/auth/registration" replace />,
  },
  {
    path: 'auth',
    async lazy() {
      const { FormContainer } = await import(
        '@/pages/Auth/FormContainer/FormContainer'
      )
      return { Component: FormContainer }
    },
    children: [
      {
        index: true,
        path: 'login',
        async lazy() {
          const { FormLogin } = await import(
            '@/pages/Auth/forms/Login/FormLogin'
          )
          return { Component: FormLogin }
        },
      },
      {
        path: 'registration',
        async lazy() {
          const { FormRegister } = await import(
            '@/pages/Auth/forms/Register/FormRegister'
          )
          return { Component: FormRegister }
        },
      },
      {
        path: 'post-registration',
        async lazy() {
          const { SchemaPostRegistrationProvider } = await import(
            '@/pages/Auth/forms/PostRegistration/SchemaPostRegistrationProvider'
          )
          return { Component: SchemaPostRegistrationProvider }
        },
      },
    ],
  },
  {
    path: 'legal',
    async lazy() {
      const { LegalContainer } = await import('@/pages/Legal/LegalContainer')
      return { Component: LegalContainer }
    },
    children: [
      {
        path: 'privacy-policy',
        async lazy() {
          const { PrivacyPolicy } = await import('@/pages/Legal/PrivacyPolicy')
          return { Component: PrivacyPolicy }
        },
      },
      {
        path: 'news-receive-approve-policy',
        async lazy() {
          const { NewsReceiveApprovePolicy } = await import(
            '@/pages/Legal/NewsReceiveApprovePolicy'
          )
          return { Component: NewsReceiveApprovePolicy }
        },
      },
      {
        path: 'send-info-to-education-policy',
        async lazy() {
          const { SendInfoToEducationPolicy } = await import(
            '@/pages/Legal/SendInfoToEducationPolicy'
          )
          return { Component: SendInfoToEducationPolicy }
        },
      },
      {
        path: 'confidential-policy',
        async lazy() {
          const { ConfidentialPolicy } = await import(
            '@/pages/Legal/ConfidentialPolicy'
          )
          return { Component: ConfidentialPolicy }
        },
      },
    ],
  },
  {
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        path: '/navigator',
        async lazy() {
          const { NavigatorPage } = await import(
            '@/pages/Navigator/NavigatorPage'
          )
          return { Component: NavigatorPage }
        },
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="navigator" replace />,
  },
]
